<template>
  <div>
    <div
      class="event-board"
      style="text-align: center;"
    >
      <div class="doctorVoice-banner-202405">
        <div
          v-for="sIndex in sectionCount"
          :key="sIndex"
          :class="'dv-div' + sIndex.toString() + ' dv-div-wrap'"
        >

          <div
            v-for="(state, index) in stepStates.slice((sIndex - 1) * 3, sIndex * 3)"
            :key="(sIndex * 3) + index + 1"
            :class="'voice' + (index + 1).toString() + ' open-event'"
          >
            <a><img
              :src="getImagePath(state)"
              :class="getImageClass(state)"
              :style="{ display: 'block', cursor: (!state.includes('default') ? 'default' : 'pointer') }"
              @click.stop="getImageClick(state, sIndex)"
            ></a>
          </div>

        </div>

        <!-- 이벤트 자세히 보러가기 -->
        <div class="btn-open-detail">
          <a
            title="이벤트 자세히 보러가기"
            @click.stop="$router.push({ name: 'app-event-main', query: { id: noticeId, entry: 191 } }).catch(() => {})"
          ><img
            src="@/assets/images/events/event240430/event-detail.png"
            alt="이벤트 자세히 보러가기"
          ></a>
        </div>
        <!-- 배경 -->
        <img
          src="@/assets/images/events/event240430/banner-bg-2406.png"
          alt="닥터보이스"
        >
      </div>
    </div>

    <component
      :is="popupComponent"
      ref="preProcessPopup"
      :event-id="eventId"
      @close="closePreProcessPopup"
    />
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>
<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { setStatistics } from '@/common/statistics/service'

require('@/assets/scss/event.scss')

export default {
  props: {
    eventId: {
      type: Number,
      required: true,
    },
    noticeId: {
      type: Number,
      required: true,
    },
    contentId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepStates: [],
      sectionCount: 3,
      sectionStepCount: 3,
      today: dayjs().format('YYYY-MM-DD'),
      completeEvent: false,
      takeKeyCountTotal: 0,
      openBoxCountTotal: 0,
      isProcessing: false,
      isLoggedInUser: false,
      selectedSection: 0,
      stDateSection: ['2024-06-04', '2024-06-12', '2024-06-22'],
      edDateSection: ['2024-06-11', '2024-06-21', '2024-06-30'],
      takeKeyCountSection: [0, 0, 0],
      openBoxCountSection: [false, false, false],
    }
  },
  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName', // 회원 로그인 여부
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    }),
    popupComponent() {
      return () => import('@/components/popups/EventJoinAgreeForm.vue')
    },
  },
  watch: {
    shaEnLicenseNum() {
      if (this.shaEnLicenseNum) {
        this.fetchEventHistory()
        this.fetchEventComplete()
      }
    },
    userName() {
      if (this.userName) {
        this.fetchEventHistory()
        this.fetchEventComplete()
      } else {
        this.isLoggedInUser = false
      }
    },
  },
  async created() {
    this.isLoggedInUser = await isUserLoggedIn()

    await this.fetchEventComplete()
    await this.fetchEventHistory()
  },
  methods: {
    getImagePath(state) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`@/assets/images/events/event240430/${state}.png`)
    },
    getImageClass(state) {
      if (typeof state === 'string') {
        return state.replace(/[0-9]/g, '')
      }
      return ''
    },
    getImageClick(state, sIndex) {
      const classState = this.getImageClass(state)
      if (classState === 'default') {
        return this.takeKey(sIndex)
      }
      if (classState === 'open') {
        return this.moveEventDetail()
      }

      return ''
    },
    async getEventHistoryWithId(isReward) {
      const result = await axios.get(`/fu/event/hiscount/${this.eventId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: isReward
        },
      })
        .then(rs => rs.data)
        .catch((err) => {
          this.showAlertDialog(`이벤트 ${isReward ? '보상' : '참여'} 여부 체크 도중 오류가 발생하였습니다.\n잠시 후에 다시 시도하여 주시기 바랍니다.\n${err}`)
          return null
        })

      return result
    },
    async getEventHistoryWithDate(isReward, startDate, endDate) {
      const result = await axios.get(`/fu/event/hiscount/${this.eventId}/${startDate}/${endDate}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: isReward
        },
      })
        .then(rs => rs.data)
        .catch((err) => {
          this.showAlertDialog(`이벤트 기간별 ${isReward ? '보상' : '참여'} 여부 체크 도중 오류가 발생하였습니다.\n잠시 후에 다시 시도하여 주시기 바랍니다.\n${err}`)
          return null
        })

      return result
    },
    async fetchEventHistory() {
      if (!this.shaEnLicenseNum) {
        // this.showAlertDialog('사용자 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.')
        return
      }

      // 로그인 하지 않은 회원인 경우만 초기 (default) 버튼으로 표시
      const isLogged = await isUserLoggedIn()
      if (!this.join || isLogged) {
        this.takeKeyCountTotal = await this.getEventHistoryWithId(false)
        this.openBoxCountTotal = await this.getEventHistoryWithId(true)

        for (let dateIndex = 0; dateIndex < this.sectionCount; dateIndex += 1) {
          // 기간 별 참여 적립 수 가져오기
          if (this.today >= this.stDateSection[dateIndex]) {
            // eslint-disable-next-line no-await-in-loop
            this.$set(this.takeKeyCountSection, dateIndex, await this.getEventHistoryWithDate(false, this.stDateSection[dateIndex], this.edDateSection[dateIndex]))
          }
          // 기간 별 박스 오픈 수 가져오기
          if (this.today >= this.stDateSection[dateIndex]) {
            // eslint-disable-next-line no-await-in-loop
            this.$set(this.openBoxCountSection, dateIndex, await this.getEventHistoryWithDate(true, this.stDateSection[dateIndex], this.edDateSection[dateIndex]))
          }
        }

        // 참여상태 갱신
        let keyIdx = 0
        let boxIdx = 0
        for (let section = 0; section < this.sectionCount; section += 1) {
          // 참여 기간
          // const sDate = this.stDateSection[section]
          const eDate = this.edDateSection[section]
          const takeKeyCount = this.takeKeyCountSection[section]
          // const openBoxCount = this.openBoxCountSection[section]
          const { openBoxCountTotal } = this

          for (let step = 0; step < this.sectionStepCount; step += 1) {
            const currentStep = section * 3 + step

            if (step + 1 <= takeKeyCount) { /// /// 적립 확인 ->
              // 적립 -> 됨
              keyIdx += 1
              if ((keyIdx - 1) % this.sectionStepCount === 2) { /// /// 박스 확인 =>
                // 박스 => 맞음
                boxIdx += 1
                /// /// 오픈 확인 ==>
                if (boxIdx <= openBoxCountTotal) {
                  // 오픈 ==> 함
                  this.$set(this.stepStates, currentStep, ('success'))
                } else {
                  // 오픈 ==> 안함
                  this.$set(this.stepStates, currentStep, ('open'))
                }
              } else {
                // 박스 => 아님
                this.$set(this.stepStates, currentStep, (`completion${section + 1}`))
              }
            } else if (eDate < this.today) { // 적립 -> 안됨    /// /// 기간 확인 -->
              // 기간 --> 지남
              this.$set(this.stepStates, currentStep, ('fail'))
            } else {
              // 기간 --> 지남
              this.$set(this.stepStates, currentStep, (`default${section + 1}`))
            }
          }
        }
      } else {
        for (let section = 0; section < this.sectionCount; section += 1) {
          for (let step = 0; step < this.sectionStepCount; step += 1) {
            const currentStep = section * 3 + step
            this.$set(this.stepStates, currentStep, (`default${section + 1}`))
          }
        }
      }
    },
    async fetchEventComplete() {
      if (!this.shaEnLicenseNum) {
        this.showAlertDialog('사용자 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.')
        return
      }
      await axios.get(`/fu/event/complete/${this.eventId}/${this.contentId}`, {
        params: {
          userHash: this.shaEnLicenseNum,
          isRewardFin: false
        },
      })
        .then(rs => {
          if (rs.data) {
            this.completeEvent = true
          } else this.completeEvent = false
        })
        .catch(() => null)
    },
    moveEventDetail() {
      this.showConfirmDialog('선물박스를 오픈할 수 있습니다. 이벤트 페이지로 이동하시겠습니까?', (result) => {
        if (result) {
          this.$router.push({ name: 'app-event-main', query: { id: this.noticeId, entry: 191 } }).catch(() => { })
        }
      })
    },
    async takeKey(sIndex) {
      if (this.isProcessing) return
      this.isProcessing = true

      if (!this.shaEnLicenseNum) {
        this.showAlertDialog('사용자 정보를 확인할 수 없습니다.\n관리자에게 문의해주세요.')
        this.isProcessing = false
        return
      }

      this.selectedSection = sIndex
      if (this.join) {
        const isLogged = await isUserLoggedIn()
        /// / 회원
        if (isLogged) {
          /// / 로그인 상태
          this.checkEventDate()
        } else {
          /// / 로그아웃 상태
          this.showConfirmDialog('이벤트 참여 및 내역 조회를 위해서는 로그인이 필요합니다.\n지금 로그인하시겠습니까?', (result) => {
            if (result) {
              this.isProcessing = false
              this.redirectLogin()
            } else {
              // 로그인 취소 눌러도 프로세스 종료
              this.isProcessing = false
            }
          })
        }
      } else {
        /// / 비회원
        this.checkEventDate()
      }
    },
    async checkEventAgree() {
      if (parseInt(this.takeKeyCountTotal, 10) === 0 && !this.join) {
        // 동의 내역 X : 이벤트 참여 동의 팝업 (confirm)
        this.$refs.preProcessPopup.call()
      } else {
        // 동의 내역 O : 열쇠 적립
        this.createHistoryTakeKey()
      }
    },
    checkEventDate() {
      if (this.completeEvent) {
        this.showAlertDialog('이미 참여한 컨텐츠 입니다.')
        this.isProcessing = false
        return
      }

      if (this.selectedSection === 1) {
        if (this.today < this.stDateSection[0]) {
          this.showAlertDialog(`${this.changeDateFormat(this.stDateSection[0])}부터 참여 가능합니다.`)
          this.isProcessing = false
          return
        }
      }
      if (this.selectedSection === 2) {
        if (this.today < this.stDateSection[1]) {
          this.showAlertDialog(`${this.changeDateFormat(this.stDateSection[1])}부터 참여 가능합니다.`)
          this.isProcessing = false
          return
        }
      }
      if (this.selectedSection === 3) {
        if (this.today < this.stDateSection[2]) {
          this.showAlertDialog(`${this.changeDateFormat(this.stDateSection[2])}부터 참여 가능합니다.`)
          this.isProcessing = false
          return
        }
      }

      this.checkEventAgree()
    },
    async createHistoryTakeKey() {
      const isLogged = await isUserLoggedIn()

      axios.post(`/fu/event/history`, {
        EventId: this.eventId,
        ContentId: this.contentId,
        UserHash: this.shaEnLicenseNum,
        UserName: (isLogged) ? this.userName : this.ysrUserName,
        MedicalDept: this.medicalDept,
        UserWorkAddress: this.hAddress
      })
        .then(async () => {
          setStatistics(190, this.eventId)
          this.completeEvent = true

          await this.fetchEventHistory()

          if (parseInt(this.takeKeyCountTotal % 3, 10) === 0 && this.takeKeyCountTotal > 0) {
            this.isProcessing = false
            this.moveEventDetail()
          } else {
            this.showAlertDialog(`스티커를 획득하였습니다.\n스티커 3개를 모아서 선물박스 1개를 획득해 보세요.`)
            this.isProcessing = false
          }
        }).catch(error => {
          if (error.response && error.response.data) {
            this.showAlertDialog(error.response.data)
          } else {
            this.showAlertDialog('오류가 발생했습니다.\n관리자에게 문의해주세요.')
          }
          this.isProcessing = false
        })
    },
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-medical-trend-main' }, params: { id: this.contentId } }).catch(() => { })
    },
    closePreProcessPopup(isConfirm) {
      if (isConfirm) {
        // 열쇠 적립
        this.createHistoryTakeKey()
      } else {
        this.isProcessing = false
      }
    },
    changeDateFormat(dateStr) {
    // 날짜 문자열을 Date 객체로 변환
      const date = new Date(dateStr)
      // 월과 일을 추출하여 새로운 형식으로 문자열 생성
      const formattedDate = `${date.getMonth() + 1}월 ${date.getDate()}일부터`
      return formattedDate
    },
  }
}
</script>

<style scoped>
body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  margin: 0 auto;
}

area:hover {
  cursor: pointer;
}

.event-board {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.event-checked img {
  display: none;
  position: absolute;
}
</style>
